import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';

import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            //new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Dashboard', 'Pages.Main.Dashboard', 'flaticon2-dashboard', '/app/main/home'),
            new AppMenuItem('Check Stock', 'Pages.Main.Check.Stock', 'flaticon2-check-mark', '/app/main/check-stock'),
            new AppMenuItem('Purchase Stock', 'Pages.Main.Purchase.Stock', 'flaticon2-box', '/app/main/purchase-stock'),
            new AppMenuItem('Stock Out', 'Pages.Main.Stock.Out', 'flaticon2-open-box', '/app/main/stock-out'),
            new AppMenuItem('Field Requests', 'Pages.Main.Field.Requests', 'flaticon2-delivery-truck', '/app/main/transfer-requests'),
            new AppMenuItem('Stock Take', 'Pages.Main.Stock.Take', 'flaticon2-box-1', '/app/main/stock-take'),
            new AppMenuItem('Manage Vendors', 'Pages.Main.Manage.Vendors', 'flaticon-buildings', '/app/main/manage-vendors'),
            new AppMenuItem('Manage Vendor Item Lists', 'Pages.Main.Manage.Vendor.Item.Lists', 'flaticon2-list', '/app/main/manage-vendor-item-list'),
            new AppMenuItem('Manage Inventory Lists', 'Pages.Main.Manage.Inventory.Lists', 'flaticon2-list-1', '/app/main/manage-inventory-lists'),
            new AppMenuItem('Manage Warehouses', 'Pages.Main.Manage.Warehouses', 'flaticon2-group', '/app/main/manage-warehouses'),
            new AppMenuItem('Reports', 'Pages.Main.Reports', 'flaticon2-document', '', [], [
                new AppMenuItem('Stock Out', 'Pages.Main.Reports.Stock.Out', 'flaticon2-circle-vol-2', '/app/main/reports/stock-out-report'),
                new AppMenuItem('SKU Usage', 'Pages.Main.Reports.SKU.Usage', 'flaticon2-circle-vol-2', '/app/main/reports/sku-usage'),
                new AppMenuItem('Costing Issues', 'Pages.Main.Reports.Costing.Issues', 'flaticon2-circle-vol-2', '/app/main/reports/costing-issues'),
                new AppMenuItem('Inventory Value', 'Pages.Main.Reports.Inventory.Value', 'flaticon2-circle-vol-2', '/app/main/reports/inventory-value'),
                new AppMenuItem('Inventory Count', 'Pages.Main.Reports.Inventory.Count', 'flaticon2-circle-vol-2', '/app/main/reports/inventory-count'),
                new AppMenuItem('Inventory Adjustment', 'Pages.Main.Reports.Inventory.Adjustment', 'flaticon2-circle-vol-2', '/app/main/reports/inventory-adjustment'),
                new AppMenuItem('Non Inventory', 'Pages.Main.Reports.Non.Inventory', 'flaticon2-circle-vol-2', '/app/main/reports/non-inventory')
            ]),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
